import React from "react"
import * as navigationStyles from "./navigation.module.css"
import { Link } from "gatsby"

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)


export default function Navigation({ children }) {
  return <nav className={navigationStyles.navigation}>
    <Link className={navigationStyles.title} to="/">
      <h3>RÖFES Plattesammlig</h3>
    </Link>
    <ul>
      <ListLink to="#roefe-in-amerika">Röfe in Amerika</ListLink>
      <ListLink to="#uftritte">d Uftritte</ListLink>
      <ListLink to="#das-erwartet-di">das erwartet di</ListLink>
      <ListLink to="#dr-roefe">dr Röfe</ListLink>
      <ListLink to="#musig">d Musig</ListLink>
      <ListLink to="#so-toents">so tönts</ListLink>
      <ListLink to="#kontakt">di Kontakt</ListLink>
      <ListLink to="#press">d Press</ListLink>
    </ul>
    {children}
  </nav>
}